@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=AR+One+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Mono&display=swap");
* {
  padding: 0;
  margin: 0;
}

.bigbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

/* Navbar */
.navbar {
  background: transparent;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 4.5rem;
  /* margin-top: -9 0rem; */
  color: aliceblue;
  position: fixed;
  font-family: "Encode Sans SC", sans-serif;
  z-index: 1;
}

.nav-items {
  width: auto;
  display: flex;
  gap: 2rem;
  margin-right: 8rem;
  margin-top: 0.5rem;
}

.Login {
  width: 4rem;
  height: 2rem;
  background-color: #ad72fe;
  color: whitesmoke;
  border: solid #ad72fe;
  border-width: 2px;
  font-family: "AR One Sans", sans-serif;
  border-radius: 2px;
  font-weight: bolder;
  top: 50%; /* Adjusted to 50% */
  transform: translateY(-50%);
  right: 2rem;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Create-Account {
  width: 6rem;
  height: 1.9rem;
  background: transparent;
  color: #ad72fe;
  border: solid #ad72fe;
  border-width: 2px;
  font-family: "AR One Sans", sans-serif;
  border-radius: 2px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 53%;
  transform: translateY(-50%);
  right: 8rem;
  cursor: pointer;
}

.Login:hover {
  background-color: #8c5cd0;
  border: solid #8c5cd0;
}
.Create-Account:hover {
  background-color: #282727b4;
}
.Logo {
  position: absolute;
  left: 3rem;
  height: 3.5rem;
  top: 10%;
  background: -webkit-linear-gradient(#ad72fe, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.5rem;
  font-weight: bolder;
  font-family: "Space Mono", monospace;
}

.logout {
  position: absolute;
  margin-top: -1.4rem;
  right: 5rem;
  border-left: solid #38363a;
  border-width: 2px;
  height: 2.5rem;
  width: 3rem;
  display: flex;
  align-items: center;
}
.logout-text {
  position: absolute;
  left: 3rem;
  height: 2.5rem;
  bottom: 0rem;
  top: 0.5rem;
  /* filter: hue-rotate(64deg); */
  cursor: pointer;
  color: #ad72fe;
  text-decoration: none;
}

.logout-img {
  position: absolute;
  right: 3.5rem;
  height: 2.5rem;
  bottom: 0rem;
  bottom: 0.4rem;
  filter: hue-rotate(64deg);
  cursor: pointer;
  color: whitesmoke;
  text-decoration: none;
}

.goto-dashboard {
  position: absolute;
  right: 5rem;
  height: 2.5rem;
  width: 8rem;
  bottom: 0rem;
  cursor: pointer;
  z-index: 1;
  font-size: 0.9rem;
  background-color: #ad72fe;
  color: whitesmoke;
  text-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "AR One Sans", sans-serif;
  border-radius: 5px;
}

.goto-dashboard:hover {
  background: #8c5cd0;
  /* border: solid #a262fb; */
}

/* CodeEditor */
.Code-Editor {
  height: 100%;
  width: 100%;
  font-family: "Times New Roman", Times, serif;
}

.code-editor-box {
  height: 100%;
  width: 75%;
  margin-top: 4.5rem;
  margin-left: 0;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: hidden;
}

.run-btn {
  margin-left: 4%;
  font-family: "AR One Sans", sans-serif;
  width: 5rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  background-color: #ad72fe;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 15rem;
}

.io {
  position: absolute;
  height: 100vh;
  top: 0;
  margin-top: 4.5rem;
  width: 29%;
  margin-left: 79%;
  display: flex;
  flex-direction: column;
  color: whitesmoke;
  font-family: "Encode Sans SC", sans-serif;
}

.input {
  margin-top: 2rem;
  margin-left: 1rem;
  color: #ad72fe;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
}
.input-box {
  border-radius: 0px;
  margin-top: 1rem;
  width: 80%;
  height: 10rem;
  color: whitesmoke;
  border-style: none;
  vertical-align: text-top;
  resize: none;
  border-width: 1px;
  background-color: rgba(0, 0, 0, 0.234);
}
.output {
  margin-top: 16rem;
  margin-left: 1rem;
  color: #ad72fe;
  font-weight: bolder;
  position: absolute;
  width: 85%;
}

.output-box {
  border-radius: 5px;
  margin-top: 1rem;
  height: 8rem;
  background-color: rgba(0, 0, 0, 0.234);
  width: 93%;
  border-width: 1px;
  color: rgb(172, 166, 166);
  font-size: 12px;
  white-space: pre-wrap;
}

.runimg {
  height: 1rem;
  position: absolute;
  margin-right: 3.4rem;
}

.run-btn-text {
  margin-left: 0.3rem;
  margin-top: -0.1rem;
}

.status {
  margin-left: 1rem;
  margin-top: 2rem;
}

.time {
  margin-top: 0.5rem;
  margin-left: 1rem;
}

.memory {
  margin-top: 0.5rem;
  margin-left: 1rem;
}

#languages {
  left: 19rem;
  margin-top: 1.1rem;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ad72fe;
  color: #ad72fe;
  border-radius: 5px;
  width: 19rem;
  position: fixed;
  height: 70%;
  background-color: transparent;
  height: 2.7rem;
  z-index: 1;
}

#languages:focus {
  outline: none;
}

#languages option {
  font-size: 18px;
  background: #282828;
  color: whitesmoke;
}

#languages option:hover {
  background-color: #ad72fe;
  background-image: linear-gradient(315deg, #ad72fe 0%, #05d6d9 74%);
  color: #fff;
}

#languages option::after {
  width: 2rem;
}

#themes {
  left: 39rem;
  margin-top: 1.1rem;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ad72fe;
  border-radius: 5px;
  width: 240px;
  position: fixed;
  height: 70%;
  background-color: transparent;
  color: #ad72fe;
  height: 2.7rem;
  z-index: 1;
}

#themes:focus {
  outline: none;
}

#themes option {
  font-size: 16px;
  background: #282828;
  color: whitesmoke;
}

#themes option:hover {
  background-color: #ad72fe;
  background-image: linear-gradient(315deg, #ad72fe 0%, #05d6d9 74%);
  color: #fff;
}

.save-btn {
  position: fixed;
  z-index: 1;
  color: #ad72fe;
  height: 2.5rem;
  width: 6rem;
  font-size: medium;
  background: transparent;
  border: solid #ad72fe;
  right: 25%;
  margin-top: 1.2rem;
  border-width: 1px;
  cursor: pointer;
  /* display: none; */
}

.save-btn:hover {
  background-color: #282727b4;
}
.landing-1 {
  margin-top: -1.9rem;
  width: 100vw;
  height: 50rem;
  background: url("./assets/galaxy.jpg");
  background-size: cover;
  box-shadow: inset 0 0 100em 12em #000;
  /* filter: brightness(70%); */
  display: flex;
  justify-content: center;
}

.welcome-container {
  position: absolute;
  margin-left: -6rem;
  height: 10rem;
  width: 80rem;
  height: 30rem;
  margin-top: 11rem;
}
.welcome-text {
  color: whitesmoke;
  font-family: "Lilita One", cursive;
  margin-left: 6rem;
  margin-top: 5%;
  position: absolute;
  font-size: 5rem;
}
.welcome-passage {
  color: whitesmoke;
  font-family: "AR One Sans", sans-serif;
  position: absolute;
  margin-left: 6rem;
  margin-top: 20%;
  font-size: 1.5rem;
  font-weight: lighter;
  width: 45rem;
}

.code-btn {
  position: absolute;
  margin-top: 28%;
  margin-left: 6rem;
  width: 7rem;
  height: 2.5rem;
  font-family: "AR One Sans", sans-serif;
  background-color: #ad72fe;
  color: whitesmoke;
  border: solid #ad72fe;
  border-radius: 8px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.code-btn:hover {
  background-color: transparent;
  color: #ad72fe;
}

.code-editor-gif {
  position: absolute;
  margin-top: 4rem;
  margin-left: 53rem;
  height: 20rem;
  width: 30rem;
  opacity: 0.9;
  border-radius: 2rem;
}

.landing-2 {
  height: 45rem;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(253, 253, 253);
}
.feature-container {
  position: absolute;
  margin-left: -7rem;
  height: 10rem;
  width: 85rem;
  height: 25rem;
  margin-top: 4rem;
}
.line-1 {
  position: absolute;
  border: solid #ad72fe;
  width: 10rem;
  height: 0.1rem;
  background-color: #ad72fe;
  margin-top: -10rem;
  margin-left: 44rem;
}
.Feature-text {
  color: #282828;
  font-family: "Lilita One", cursive;
  font-size: 3rem;
  margin-left: 55rem;
}

.Feature-list {
  font-family: "AR One Sans", sans-serif;
  color: #282828;
  position: absolute;
  margin-left: 57rem;
  margin-top: 2rem;
  font-size: 1.4rem;
}

.compile-2 {
  height: 15rem;
  margin-left: 5rem;
  margin-top: -10rem;
  border-radius: 10px;
  opacity: 0.9;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.files-png {
  position: absolute;
  height: 15.1rem;
  border-radius: 10px;
  opacity: 0.8;
  margin-left: -25rem;
  margin-top: 10rem;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.lang-images {
  position: absolute;
  margin-left: 60rem;
  margin-top: 12rem;
}
.lang-images img {
  height: 2rem;
  margin-top: 1rem;
  margin-right: 2rem;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.landing-3 {
  margin-top: -1.9rem;
  width: 100vw;
  height: 60rem;
  background: black;
  background-size: cover;
  box-shadow: inset 0 0 100em 12em #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.connection-section {
  width: 90rem;
  height: 100%;
  position: absolute;
  /* margin-top: 10rem; */
}
.connection-text {
  color: whitesmoke;
  font-family: "Lilita One", cursive;
  font-size: 4rem;
  margin-left: 8rem;
  margin-top: 13rem;
}
.end-note {
  color: whitesmoke;
  font-family: "AR One Sans", sans-serif;
  position: absolute;
  font-size: 2rem;
  margin-left: 8rem;
  margin-top: 2rem;
}
.social-images {
  margin-left: 6.3rem;
}
.social-images img {
  height: 3rem;
  margin-top: 10rem;
  margin-left: 2rem;
}

.social-images .github {
  filter: invert(1);
}

.coding-img {
  height: 18rem;
  margin-left: 60rem;
  margin-top: -19rem;
  position: absolute;
  filter: hue-rotate(95deg);
}

.lds-ring {
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  margin-top: -1.1rem;
  margin-left: -5rem;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  margin: 8px;
  border: 3px solid black;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

footer {
  margin-top: 55rem;
  font-size: large;
  font-family: "Courier New", Courier, monospace;
  color: whitesmoke;
  z-index: 1;
}

.footer-link {
  color: #ad72fe;
  cursor: pointer;
}

/* Login Page */
.main-login-box {
  position: absolute;
  background-size: 65%;
  background-position-x: right;
  background-position-y: center;
  background-repeat: no-repeat;
  height: 35rem;
  width: 70rem;
  margin-top: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
}
.login-box {
  position: absolute;
  height: 38rem;
  width: 30rem;
  /* border: solid 1px rgb(213, 199, 237); */
  margin-bottom: 1rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(30, 27, 27);
}

.login-email {
  height: 3rem;
  width: 23rem;
  margin-top: 6rem;
  background-color: #333131;
  border-style: none;
  color: whitesmoke;
  text-align: center;
  font-size: medium;
  position: absolute;
}
.login-password {
  height: 3rem;
  width: 23rem;
  margin-top: 15rem;
  background-color: #333131;
  border-style: none;
  color: whitesmoke;
  text-align: center;
  font-size: medium;
  position: absolute;
}

.login-btn {
  position: absolute;
  margin-top: 26rem;
  height: 3rem;
  width: 6rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: larger;
  background: transparent;
  border: solid #ad72fe;
  color: #ad72fe;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-btn:hover {
  background-color: #282727b4;
}

.login-gif {
  position: absolute;
  height: 15rem;
  margin-bottom: 16rem;
  margin-top: 5rem;
  /* filter: hue-rotate(60deg); */
}
.create-account-text {
  color: rgb(215, 196, 246);
  font-family: "AR One Sans", sans-serif;
  margin-top: 34rem;
}

.eye-icon {
  position: absolute;
  height: 1.5rem;
  margin-top: 15rem;
  margin-right: 19rem;
  filter: hue-rotate(-9deg) brightness(70%);
  cursor: pointer;
}

/* Create Account */
.main-createaccount-box {
  position: absolute;
  background-size: 65%;
  background-position-x: right;
  background-position-y: center;
  background-repeat: no-repeat;
  height: 35rem;
  width: 70rem;
  margin-top: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
}
.createaccount-box {
  position: absolute;
  height: 38rem;
  width: 30rem;
  /* border: solid 1px rgb(213, 199, 237); */
  margin-bottom: 1rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(30, 27, 27);
}
.createaccount-name {
  height: 3rem;
  width: 23rem;
  margin-bottom: 10rem;
  background-color: #333131;
  border-style: none;
  color: whitesmoke;
  text-align: center;
  font-size: medium;
  position: absolute;
}
.createaccount-email {
  height: 3rem;
  width: 23rem;
  margin-bottom: 2rem;
  background-color: #333131;
  border-style: none;
  color: whitesmoke;
  text-align: center;
  font-size: medium;
  position: absolute;
}
.createaccount-password {
  height: 3rem;
  width: 23rem;
  margin-top: 6rem;
  background-color: #333131;
  border-style: none;
  color: whitesmoke;
  text-align: center;
  font-size: medium;
  position: absolute;
}
.createaccount-repassword {
  height: 3rem;
  width: 23rem;
  margin-top: 14rem;
  background-color: #333131;
  border-style: none;
  color: whitesmoke;
  text-align: center;
  font-size: medium;
  position: absolute;
}
.createaccount-btn {
  position: absolute;
  margin-top: 24rem;
  height: 3rem;
  width: 6rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: larger;
  background: transparent;
  border: solid #ad72fe;
  color: #ad72fe;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createaccount-btn:hover {
  background-color: #282727b4;
}

.create-account-gif {
  position: absolute;
  height: 11rem;
  margin-bottom: 24rem;
}

/* Dashboard */
.file-box {
  height: 100%;
  width: 100%;
  margin-top: 4.5rem;
  margin-left: 0;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: hidden;
  background-color: #060408;
}
.add-files {
  position: fixed;
  margin-top: 42%;
  margin-left: 89%;
  width: 8rem;
  height: 3rem;
  background-color: #ad72fe;
  border: none;
  color: whitesmoke;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.1rem;
  cursor: pointer;
}

.add-files:hover {
  background-color: transparent;
  border: solid #ad72fe;
  color: #ad72fe;
}

.filebox-heading {
  color: whitesmoke;
  font-family: "AR One Sans", sans-serif;
  background: -webkit-linear-gradient(#ad72fe, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 1rem;
  margin-left: 3.3rem;
}

.file-list {
  position: absolute;
  left: 3.1rem;
  top: 5rem;
  height: 100%;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.files {
  margin-top: 2rem;
  height: 15rem;
  width: 20rem;
  background-color: #282828;
  border-radius: 10px;
  cursor: pointer;
}

.filename {
  color: #ad72fe;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  position: absolute;
  margin-top: 9rem;
  margin-left: 1rem;
  text-decoration: underline;
}

.programming-language {
  font-family: "Encode Sans SC", sans-serif;
  font-size: 0.8rem;
  position: absolute;
  margin-top: 11rem;
  margin-left: 11.2rem;
  color: rgb(137, 135, 135);
}
.date-created {
  font-family: "Encode Sans SC", sans-serif;
  font-size: 0.8rem;
  position: absolute;
  margin-top: 11rem;
  margin-left: 1rem;
  color: rgb(137, 135, 135);
}
.last-edited {
  font-family: "Encode Sans SC", sans-serif;
  font-size: 0.8rem;
  position: absolute;
  margin-top: 12.5rem;
  margin-left: 1rem;
  color: rgb(137, 135, 135);
}

.thumbnail {
  position: absolute;
  margin-top: 1rem;
  margin-left: 1rem;
  height: 7rem;
  width: 18rem;
  background-color: rgb(225, 204, 233);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail-img {
  position: absolute;
  height: 5rem;
}

.trash-can {
  position: absolute;
  height: 1.5rem;
  margin-top: -0.3rem;
  margin-left: 19rem;
  filter: hue-rotate(20deg);
  cursor: pointer;
}

.create-file-box {
  position: absolute;
  margin-top: 47rem;
  height: 25rem;
  width: 30rem;
  background-color: #282828;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filename-input {
  position: absolute;
  margin-top: 10rem;
  height: 3rem;
  width: 25rem;
  background-color: transparent;
  text-align: center;
  color: whitesmoke;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1rem;
  border: solid #ad72fe;
}

.create-file-btn {
  position: absolute;
  margin-top: 15rem;
  width: 7rem;
  height: 2.5rem;
  font-family: "AR One Sans", sans-serif;
  background-color: transparent;
  color: #ad72fe;
  border: solid #ad72fe;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.create-file-btn:hover {
  background-color: #ad72fe;
  color: whitesmoke;
}

.folder-img {
  position: absolute;
  height: 7rem;
  margin-bottom: 12rem;
  margin-left: 38%;
  margin-top: 3rem;
  border-radius: 3px;
}

/* Delete dialog */

.trash-can-img {
  position: absolute;
  height: 10rem;
  margin-bottom: 12rem;
  margin-left: 33%;
  margin-top: 1rem;
  border-radius: 3px;
}

.delete-file-btn {
  position: absolute;
  margin-top: 15rem;
  width: 7rem;
  height: 2.5rem;
  margin-right: 8rem;
  font-family: "AR One Sans", sans-serif;
  background-color: transparent;
  color: #ad72fe;
  border: solid #ad72fe;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}
.delete-cancel-file-btn {
  position: absolute;
  margin-top: 15rem;
  width: 7rem;
  height: 2.5rem;
  margin-left: 8rem;
  font-family: "AR One Sans", sans-serif;
  background-color: transparent;
  color: #ad72fe;
  border: solid #ad72fe;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.delete-msg {
  color: #ad72fe;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin-top: 11rem;
  font-size: large;
  position: absolute;
}

.delete-cancel-file-btn:hover,
.delete-file-btn:hover {
  background-color: #ad72fe;
  color: whitesmoke;
}

.otp-box {
  position: absolute;
  height: 25rem;
  width: 50rem;
  margin-top: 50%;
  background-size: cover;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  /* background-color: #282828; */
}

.otp-img {
  position: absolute;
  height: 20rem;
  margin-left: 25rem;
  margin-top: 0rem;
  border: none;
  filter: hue-rotate(300deg);
}

.otp-heading {
  font-family: "Space Mono", monospace;
  z-index: 1;
  color: #ad72fe;
  margin-right: 29rem;
  margin-top: 4rem;
  position: absolute;
}

.otp-input {
  z-index: 1;
  border: solid #38363a;
  background-color: transparent;
  height: 2rem;
  width: 15rem;
  position: absolute;
  margin-right: 24.5rem;
  margin-top: 9rem;
  border-width: 2px;
  text-align: center;
  color: white;
}
.verify-otp-btn {
  z-index: 1;
  height: 2rem;
  width: 5rem;
  margin-top: 13rem;
  margin-right: 34.5rem;
  position: absolute;
  background-color: transparent;
  border: solid #ad72fe;
  color: #ad72fe;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verify-otp-btn:hover {
  background-color: #333;
}

.otp-note {
  color: #ad72fe;
  z-index: 1;
  position: absolute;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin-top: 16rem;
  margin-right: 31rem;
  color: #ad72fe;
}

.lds-ring2 {
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  margin-top: -1.5rem;
  margin-left: -1.2rem;
  z-index: 1;
}

.lds-ring2 div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 8px;
  border: 3px solid #ad72fe;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ad72fe transparent transparent transparent;
}

.lds-ring2 div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring2 div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring2 div:nth-child(3) {
  animation-delay: -0.15s;
}

/* Create account error */
.err-msg {
  color: whitesmoke;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: #8c5cd0;
  margin-top: 6.5rem;
  position: absolute;
}

.err-img {
  height: 4.3rem;
  margin-top: 0.9rem;
}

.err-btn {
  position: absolute;
  height: 2rem;
  width: 3rem;
  background: transparent;
  color: #ad72fe;
  border: solid #ad72fe;
  margin-top: 9.4rem;
  cursor: pointer;
}

.err-btn:hover {
  color: whitesmoke;
  background-color: #ad72fe;
}

.resend-otp {
  color: #d7befb;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin-top: 18rem;
  margin-right: 27rem;
  position: absolute;
  font-size: 0.7rem;
}

.resend-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

/* Warning page */

.warning-bigbox {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning-box {
  border: solid #ad72fe;
  max-width: 30rem;
  width: 90%;
  height: auto;
  border-radius: 2rem;
  background-color: #ad72fe;
  margin: 10% auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: whitesmoke;
  font-family: monospace;
  padding-bottom: 2rem;
}

.warning-text {
  max-width: 26rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

.sorry-img {
  width: 36%;
  height: 64%;
  margin-top: 0rem;
}
