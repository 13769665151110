body {
  background-color: rgb(0, 0, 0);
  margin: 0;
  overflow-x: hidden;
  animation: fadeIn 0.25s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #ad72fe;
  width: 2px;
  border-radius: 5px;
}
